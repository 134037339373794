@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;700&display=swap');

*{
    margin: 0;
    box-sizing: border-box;
    
}

footer{
    /* font-family: 'Caveat', cursive; */
    max-width: 100%;

    background: #c31432; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #c31432, #240b36); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #c31432, #240b36);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
footer h2{
    font-family: 'Caveat', cursive;
    margin-top: 5px;
    font-size: 50px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
}
footer img{
    margin-bottom: 10px;
}
footer p{
    margin-bottom: 5px;
}
.colaborador {
    width: 280px;
    position: relative;
}

.colaborador .cabecalho {
    background-color:  #F0F0F0;
    border-radius: 10px 10px 0px 0px;
}
.colaborador img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: relative;
    bottom: -50px;
}

.colaborador .rodape {
    background: #FFFFFF;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.08);
    border-radius: 0px 0px 10px 10px;
    padding-top: 90px;
    padding-bottom: 40px;
}

.colaborador .rodape h4 {
    color: #6278F7;
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    margin-bottom: 8px;
}

.colaborador .rodape h5 {
    font-size: 18px;
    line-height: 22px;
    color: #212121;
    padding: 0 16px;
}
.colaborador .deletar{
    position: absolute;
    right: -10px;
    top: -10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: rgb(157, 18, 18);
    border: 2px solid white;
    cursor: pointer;
    transition: 500ms;
    font-weight: 600;
}
.colaborador .deletar:hover{
    transform: scale(1.2);
}
.botao{
    background: linear-gradient(to right, #c31432, #240b36);
    border-radius: 10px;
    font-weight: 700;
    font-size: 18px;
    padding: 32px;
    border: none;
    cursor: pointer;
    color: #FFF;
    transition: background 0.3s ease-in-out;
    margin: 32px 0;
    
}
.botao:hover{
    background: linear-gradient(to left, #c31432, #240b36);
    color: #ffffff;
}
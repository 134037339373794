@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;700&display=swap');

.time {
    text-align: center;
    padding: 32px;
    position: relative;
   
}
.cards{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    gap: 16px;
    flex-wrap: wrap;

}
.time h3{
    font-size: 32px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 8px;
    font-family: 'Caveat', cursive;
    font-weight: 700;
}

.time span{
    text-align: center;
    display: block;
    width: 50px;
    border: 2px solid;
    margin-top: 10px;
    border-radius: 10px;
}

.colorPicker{
    position: absolute;
    top: 10px;
    right: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    width: 40px;
    height: 30px;
}

  
  input[type="color"]::-webkit-color-swatch {
    border: none;
    border-radius: 2px;
    box-shadow: none;
  }
  
  input[type="color"]::-webkit-color-swatch:hover {
    cursor: pointer;
  }
  
  input[type="color"]::-webkit-color-swatch::after {
    content: "Selecionar cor";
    display: inline-block;
    padding: 4px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 2px;
  }

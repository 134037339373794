label{
    display: block;
    font-size: 24px;
    margin-bottom: 8px;
}
select{
    border: 0px;
    background-color: #FFF;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.6);
    width: 100%;
    padding: 24px;
    font-size: 24px;
    box-sizing: border-box;
}
.select{
    width: 100%;
}

.lista{
display: flex;
align-items: center;
gap: 8px;
width: 100%;
}
.lista .button{ 

    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 70px;
    width: 70px;
    font-size: 40px;
    border: 2px solid white;
    cursor: pointer;
    background: linear-gradient(to right, #c31432, #240b36);
    color: white;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.7);
    transition: 0.5s;
}
.lista .button:hover{
    transform: scale(1.1);
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.5);
}